import { createApp } from "vue";

import App from "./App.vue";

// Vuetify
import "vuetify/styles";

// CSS
import "@mdi/font/css/materialdesignicons.css";
import "./assets/main.scss";

// external
// Composables
import { registerPlugins } from "@/plugins";
import { useAuthStore } from "@/stores/useAuthStore";
import { useCustomerStore } from "@/stores/useCustomerStore";
import { useCoreDataStore } from "@/stores/useCoreDataStore";

import("./router").then(async (router: any) => {
  const app = createApp(App);

  registerPlugins(app);

  router.default.isReady().then(() => {
    app.mount("#app");
    useAuthStore();
    useCustomerStore();
    useCoreDataStore();
  });
});

if (import.meta.env.PROD) {
  // Hotjar
  const hotjarScript = document.createElement("script");
  hotjarScript.innerHTML = `
        (function(h, o, t, j, a, r) {
            h.hj = h.hj || function() {(h.hj.q = h.hj.q || []).push(arguments)};
            h._hjSettings = {hjid: 3404768, hjsv: 6};
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    `;
  document.head.appendChild(hotjarScript);

  // Polyfill IO
  let polyfillScript = document.createElement("script");
  polyfillScript.src =
    "https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver,ResizeObserver,WebAnimations,Object.fromEntries,Array.prototype.at";
  document.head.appendChild(polyfillScript);

  // Trengo
  const trengoScript = document.createElement("script");
  trengoScript.innerHTML = `
        window.Trengo = window.Trengo || {};
        window.Trengo.key = 'fs2CYvnFJvMajqhxujak';
        ((d, script, t) => {
            script = d.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://static.widget.trengo.eu/embed.js';
            d.getElementsByTagName('head')[0].appendChild(script);
        })(document);
    `;
  document.head.appendChild(trengoScript);
}
